<template>
  <!-- 客户备案 -->

  <div class="center-wrap">
    <div class="page">
      <div class="left-box">
        <v-user-menu></v-user-menu>
      </div>

      <div class="body-box">
        <div class="title-box">客户备案</div>

        <div class="list-box">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="选择城市">
              <el-cascader
                popper-class="popper-box"
                :options="options"
                clearable
                v-model="form.citys"
                placeholder="选择城市"
                :props="{value:'name',label:'name',children:'sub'}"
              ></el-cascader>
            </el-form-item>

            <el-form-item label="教材系列">
              <el-select v-model="form.book_type_id" placeholder="请选择">
                <el-option
                  v-for="(item,index) in bookTypeList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="客户名称">
              <el-input v-model="form.company_name"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="sub" :loading="loading">立即备案</el-button>

              <el-button type="primary" @click="checking" :loading="loading">查询备案</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <el-dialog title="查重结果" :visible.sync="repeatVisible">
      <el-table :data="repeatata" style="width:50vw">
        <el-table-column prop="company_name" label="客户名称" width="150"></el-table-column>
        <el-table-column prop="book_type_text" label="教材" width="200"></el-table-column>
        <el-table-column label="地址">
          <template slot-scope="scope">{{scope.row.province}}{{scope.row.city}}{{scope.row.area}}</template>
        </el-table-column>
        <el-table-column prop="record_msg" label="教材" width="200"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import UserMenu from '../components/userMenu.vue'
import { MessageBox, Form, FormItem, Cascader } from 'element-ui'

export default {
  name: 'CustomerRecord',

  components: {
    'el-cascader': Cascader,
    'v-user-menu': UserMenu,
    'el-form': Form,
    'el-form-item': FormItem,
  },

  data() {
    return {
      repeatata: [],
      repeatVisible: false,

      loading: false,
      form: {
        company_name: '',
        citys: [],
        province: '',
        city: '',
        area: '',
        book_type_id: '',
      },
      options: [],
      bookTypeList: [],
    }
  },

  created() {
    this.options = require('../assets/js/provinceAndCity.js').provinceAndCity
    this.fetchBookTypeList()
  },

  methods: {
    // 客户查重
    checking() {

    

      if (this.form.citys.length !== 2)
        return this.common.showMessage('请选择地区')
      if (!this.form.book_type_id)
        return this.common.showMessage('请选择教材系列')
      if (!this.form.company_name)
        return this.common.showMessage('请输入客户名称')

      this.loading = true
      this.$set(this.form, 'province', this.form.citys[0])
      this.$set(this.form, 'city', this.form.citys[1])
      // this.$set(this.form, 'area', this.form.citys[2])
      this.$set(this.form, 'uuid', localStorage.getItem('Wx1e2PtoP'))

      this.common
        .httpPost('/api/ajax/getSaleRecord', this.form)
        .then((ret) => {
          if (ret.data.length == 0) this.common.showMessage('未找到重复数据')
          else {
            this.repeatata = ret.data
            this.repeatVisible = true
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    // 获取教材系列列表
    fetchBookTypeList() {
      this.common.httpPost('/api/ajax/bookTypeList').then((ret) => {
        this.bookTypeList = ret.data
      })
    },

    // 提交
    sub: function () {
      if (this.form.citys.length !== 2)
        return this.common.showMessage('请选择地区')
      if (!this.form.book_type_id)
        return this.common.showMessage('请选择教材系列')
      if (!this.form.company_name)
        return this.common.showMessage('请输入客户名称')

      this.loading = true
      this.$set(this.form, 'province', this.form.citys[0])
      this.$set(this.form, 'city', this.form.citys[1])
      // this.$set(this.form, 'area', this.form.citys[2])

      this.common
        .httpPost('/api/user/addSaleRecord', this.form)
        .then((ret) => {
          this.common.showMessage(ret.msg, ret.code == 1 ? 'success' : 'error')
          if (ret.code == 1) {
            this.form = {
              company_name: '',
              citys: [],
              province: '',
              city: '',
              area: '',
              book_type_id: '',
            }
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })

      // var self = this
      // if (!self.order_code)
      //   return self.common.showMessage(self.$t('two.input_message_num')) //请填写订单编号
      // if (!self.email)
      //   return self.common.showMessage(self.$t('two.input_email_num')) //请填写邮箱号
      // if (!self.isMailboxAvailable(self.email))
      //   return self.common.showMessage(self.$t('two.input_email_num_ok')) //请填写正确邮箱号
      // if (!self.content)
      //   return self.common.showMessage(self.$t('two.input_message_content')) //请填写留言内容
      // self.common
      //   .httpPost('/api/user/createMessageBoard', {
      //     content: self.content,
      //     order_code: self.order_code,
      //     email: self.email,
      //     images: self.fileList.toString(),
      //   })
      //   .then((ret) => {
      //     // console.log(JSON.stringify(ret));
      //     self.common.showMessage(ret.msg, ret.code == 1 ? 'success' : 'error')
      //     if (ret.code == 1) {
      //       this.$router.push('/user/messageList')
      //     }
      //   })
    },
  },
}
</script>
<style scoped>
.page {
  margin: 20px 83px 44px 83px;
  display: flex;
}

.left-box {
  width: 196px;
  max-height: 1000px;
  min-height: 550px;
}

.body-box {
  width: 982px;
  margin-left: 22px;
}

.title-box {
  height: 50px;
  border: 1px solid rgba(182, 182, 182, 0.7);
  margin-bottom: 20px;
  padding-left: 30px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 50px;
}

.list-box {
  border: 1px solid rgba(182, 182, 182, 0.7);
  padding: 30px;
  min-height: 478px;
}

.list-box .el-form {
  width: 500px;
}

.list-box .el-cascader,
.list-box .el-select {
  width: 100%;
}
</style>