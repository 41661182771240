export const provinceAndCity = [
  {
    citycode: '010',
    adcode: '110000',
    name: '北京',
    center: '116.405285,39.904989',
    level: 'province',
    sub: [
      {
        citycode: '010',
        adcode: '110100',
        name: '北京市',
        center: '116.405285,39.904989',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: '022',
    adcode: '120000',
    name: '天津',
    center: '117.190182,39.125596',
    level: 'province',
    sub: [
      {
        citycode: '022',
        adcode: '120100',
        name: '天津市',
        center: '117.190182,39.125596',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '150000',
    name: '内蒙古自治区',
    center: '111.670801,40.818311',
    level: 'province',
    sub: [
      {
        citycode: '0478',
        adcode: '150800',
        name: '巴彦淖尔市',
        center: '107.416959,40.757402',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0473',
        adcode: '150300',
        name: '乌海市',
        center: '106.825563,39.673734',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0472',
        adcode: '150200',
        name: '包头市',
        center: '109.840405,40.658168',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0470',
        adcode: '150700',
        name: '呼伦贝尔市',
        center: '119.758168,49.215333',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0477',
        adcode: '150600',
        name: '鄂尔多斯市',
        center: '109.99029,39.817179',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0483',
        adcode: '152900',
        name: '阿拉善盟',
        center: '105.706422,38.844814',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0475',
        adcode: '150500',
        name: '通辽市',
        center: '122.263119,43.617429',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0482',
        adcode: '152200',
        name: '兴安盟',
        center: '122.070317,46.076268',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0479',
        adcode: '152500',
        name: '锡林郭勒盟',
        center: '116.090996,43.944018',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0471',
        adcode: '150100',
        name: '呼和浩特市',
        center: '111.670801,40.818311',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0476',
        adcode: '150400',
        name: '赤峰市',
        center: '118.956806,42.275317',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0474',
        adcode: '150900',
        name: '乌兰察布市',
        center: '113.114543,41.034126',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '410000',
    name: '河南省',
    center: '113.665412,34.757975',
    level: 'province',
    sub: [
      {
        citycode: '0398',
        adcode: '411200',
        name: '三门峡市',
        center: '111.194099,34.777338',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0379',
        adcode: '410300',
        name: '洛阳市',
        center: '112.434468,34.663041',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0374',
        adcode: '411000',
        name: '许昌市',
        center: '113.826063,34.022956',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0377',
        adcode: '411300',
        name: '南阳市',
        center: '112.540918,32.999082',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0395',
        adcode: '411100',
        name: '漯河市',
        center: '114.026405,33.575855',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0376',
        adcode: '411500',
        name: '信阳市',
        center: '114.075031,32.123274',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0396',
        adcode: '411700',
        name: '驻马店市',
        center: '114.024736,32.980169',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0391',
        adcode: '410800',
        name: '焦作市',
        center: '113.238266,35.23904',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0393',
        adcode: '410900',
        name: '濮阳市',
        center: '115.041299,35.768234',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1391',
        adcode: '419001',
        name: '济源市',
        center: '112.590047,35.090378',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0392',
        adcode: '410600',
        name: '鹤壁市',
        center: '114.295444,35.748236',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0373',
        adcode: '410700',
        name: '新乡市',
        center: '113.883991,35.302616',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0375',
        adcode: '410400',
        name: '平顶山市',
        center: '113.307718,33.735241',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0394',
        adcode: '411600',
        name: '周口市',
        center: '114.649653,33.620357',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0372',
        adcode: '410500',
        name: '安阳市',
        center: '114.352482,36.103442',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0371',
        adcode: '410100',
        name: '郑州市',
        center: '113.665412,34.757975',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0378',
        adcode: '410200',
        name: '开封市',
        center: '114.341447,34.797049',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0370',
        adcode: '411400',
        name: '商丘市',
        center: '115.650497,34.437054',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '440000',
    name: '广东省',
    center: '113.280637,23.125178',
    level: 'province',
    sub: [
      {
        citycode: '0758',
        adcode: '441200',
        name: '肇庆市',
        center: '112.472529,23.051546',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0757',
        adcode: '440600',
        name: '佛山市',
        center: '113.122717,23.028762',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0754',
        adcode: '440500',
        name: '汕头市',
        center: '116.708463,23.37102',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0752',
        adcode: '441300',
        name: '惠州市',
        center: '114.412599,23.079404',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0755',
        adcode: '440300',
        name: '深圳市',
        center: '114.085947,22.547',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0756',
        adcode: '440400',
        name: '珠海市',
        center: '113.553986,22.224979',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0759',
        adcode: '440800',
        name: '湛江市',
        center: '110.364977,21.274898',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0662',
        adcode: '441700',
        name: '阳江市',
        center: '111.975107,21.859222',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0660',
        adcode: '441500',
        name: '汕尾市',
        center: '115.364238,22.774485',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0768',
        adcode: '445100',
        name: '潮州市',
        center: '116.632301,23.661701',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0750',
        adcode: '440700',
        name: '江门市',
        center: '113.094942,22.590431',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0766',
        adcode: '445300',
        name: '云浮市',
        center: '112.044439,22.929801',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0767',
        adcode: '442100',
        name: '东沙群岛',
        center: '116.887312,20.617512',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0668',
        adcode: '440900',
        name: '茂名市',
        center: '110.919229,21.659751',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0762',
        adcode: '441600',
        name: '河源市',
        center: '114.697802,23.746266',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0753',
        adcode: '441400',
        name: '梅州市',
        center: '116.117582,24.299112',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0769',
        adcode: '441900',
        name: '东莞市',
        center: '113.746262,23.046237',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0663',
        adcode: '445200',
        name: '揭阳市',
        center: '116.355733,23.543778',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0763',
        adcode: '441800',
        name: '清远市',
        center: '113.051227,23.685022',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0751',
        adcode: '440200',
        name: '韶关市',
        center: '113.591544,24.801322',
        level: 'city',
        sub: '',
      },
      {
        citycode: '020',
        adcode: '440100',
        name: '广州市',
        center: '113.280637,23.125178',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0760',
        adcode: '442000',
        name: '中山市',
        center: '113.382391,22.521113',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '230000',
    name: '黑龙江省',
    center: '126.642464,45.756967',
    level: 'province',
    sub: [
      {
        citycode: '0457',
        adcode: '232700',
        name: '大兴安岭地区',
        center: '124.711526,52.335262',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0468',
        adcode: '230400',
        name: '鹤岗市',
        center: '130.277487,47.332085',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0464',
        adcode: '230900',
        name: '七台河市',
        center: '131.015584,45.771266',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0458',
        adcode: '230700',
        name: '伊春市',
        center: '128.899396,47.724775',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0451',
        adcode: '230100',
        name: '哈尔滨市',
        center: '126.642464,45.756967',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0452',
        adcode: '230200',
        name: '齐齐哈尔市',
        center: '123.95792,47.342081',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0455',
        adcode: '231200',
        name: '绥化市',
        center: '126.99293,46.637393',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0456',
        adcode: '231100',
        name: '黑河市',
        center: '127.499023,50.249585',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0453',
        adcode: '231000',
        name: '牡丹江市',
        center: '129.618602,44.582962',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0459',
        adcode: '230600',
        name: '大庆市',
        center: '125.11272,46.590734',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0454',
        adcode: '230800',
        name: '佳木斯市',
        center: '130.361634,46.809606',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0469',
        adcode: '230500',
        name: '双鸭山市',
        center: '131.157304,46.643442',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0467',
        adcode: '230300',
        name: '鸡西市',
        center: '130.975966,45.300046',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '650000',
    name: '新疆维吾尔自治区',
    center: '87.617733,43.792818',
    level: 'province',
    sub: [
      {
        citycode: '1906',
        adcode: '659005',
        name: '北屯市',
        center: '87.824932,47.353177',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1909',
        adcode: '659007',
        name: '双河市',
        center: '82.353656,44.840524',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1996',
        adcode: '659006',
        name: '铁门关市',
        center: '85.501218,41.827251',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0909',
        adcode: '652700',
        name: '博尔塔拉蒙古自治州',
        center: '82.074778,44.903258',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0903',
        adcode: '653200',
        name: '和田地区',
        center: '79.92533,37.110687',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0901',
        adcode: '654200',
        name: '塔城地区',
        center: '82.985732,46.746301',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0993',
        adcode: '659001',
        name: '石河子市',
        center: '86.041075,44.305886',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0906',
        adcode: '654300',
        name: '阿勒泰地区',
        center: '88.13963,47.848393',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1999',
        adcode: '659008',
        name: '可克达拉市',
        center: '80.63579,43.6832',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0990',
        adcode: '650200',
        name: '克拉玛依市',
        center: '84.873946,45.595886',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1903',
        adcode: '659009',
        name: '昆玉市',
        center: '79.287372,37.207994',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0994',
        adcode: '652300',
        name: '昌吉回族自治州',
        center: '87.304012,44.014577',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0991',
        adcode: '650100',
        name: '乌鲁木齐市',
        center: '87.617733,43.792818',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0996',
        adcode: '652800',
        name: '巴音郭楞蒙古自治州',
        center: '86.150969,41.768552',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1997',
        adcode: '659002',
        name: '阿拉尔市',
        center: '81.285884,40.541914',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1994',
        adcode: '659004',
        name: '五家渠市',
        center: '87.526884,44.167401',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0999',
        adcode: '654000',
        name: '伊犁哈萨克自治州',
        center: '81.317946,43.92186',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0908',
        adcode: '653000',
        name: '克孜勒苏柯尔克孜自治州',
        center: '76.172825,39.713431',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0998',
        adcode: '653100',
        name: '喀什地区',
        center: '75.989138,39.467664',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1998',
        adcode: '659003',
        name: '图木舒克市',
        center: '79.077978,39.867316',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0997',
        adcode: '652900',
        name: '阿克苏地区',
        center: '80.265068,41.170712',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0902',
        adcode: '650500',
        name: '哈密市',
        center: '93.51316,42.833248',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0995',
        adcode: '650400',
        name: '吐鲁番市',
        center: '89.184078,42.947613',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '420000',
    name: '湖北省',
    center: '114.298572,30.584355',
    level: 'province',
    sub: [
      {
        citycode: '027',
        adcode: '420100',
        name: '武汉市',
        center: '114.298572,30.584355',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0712',
        adcode: '420900',
        name: '孝感市',
        center: '113.926655,30.926423',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0713',
        adcode: '421100',
        name: '黄冈市',
        center: '114.879365,30.447711',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0724',
        adcode: '420800',
        name: '荆门市',
        center: '112.204251,31.03542',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0719',
        adcode: '420300',
        name: '十堰市',
        center: '110.787916,32.646907',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0717',
        adcode: '420500',
        name: '宜昌市',
        center: '111.290843,30.702636',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1728',
        adcode: '429006',
        name: '天门市',
        center: '113.165862,30.653061',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0710',
        adcode: '420600',
        name: '襄阳市',
        center: '112.144146,32.042426',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0728',
        adcode: '429004',
        name: '仙桃市',
        center: '113.453974,30.364953',
        level: 'city',
        sub: '',
      },
      {
        citycode: '2728',
        adcode: '429005',
        name: '潜江市',
        center: '112.896866,30.421215',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0716',
        adcode: '421000',
        name: '荆州市',
        center: '112.23813,30.326857',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0722',
        adcode: '421300',
        name: '随州市',
        center: '113.37377,31.717497',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0718',
        adcode: '422800',
        name: '恩施土家族苗族自治州',
        center: '109.48699,30.283114',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0715',
        adcode: '421200',
        name: '咸宁市',
        center: '114.328963,29.832798',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1719',
        adcode: '429021',
        name: '神农架林区',
        center: '110.671525,31.744449',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0711',
        adcode: '420700',
        name: '鄂州市',
        center: '114.890593,30.396536',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0714',
        adcode: '420200',
        name: '黄石市',
        center: '115.077048,30.220074',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '210000',
    name: '辽宁省',
    center: '123.429096,41.796767',
    level: 'province',
    sub: [
      {
        citycode: '0411',
        adcode: '210200',
        name: '大连市',
        center: '121.618622,38.91459',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0429',
        adcode: '211400',
        name: '葫芦岛市',
        center: '120.856394,40.755572',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0415',
        adcode: '210600',
        name: '丹东市',
        center: '124.383044,40.124296',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0416',
        adcode: '210700',
        name: '锦州市',
        center: '121.135742,41.119269',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0418',
        adcode: '210900',
        name: '阜新市',
        center: '121.648962,42.011796',
        level: 'city',
        sub: '',
      },
      {
        citycode: '024',
        adcode: '210100',
        name: '沈阳市',
        center: '123.429096,41.796767',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0413',
        adcode: '210400',
        name: '抚顺市',
        center: '123.921109,41.875956',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0417',
        adcode: '210800',
        name: '营口市',
        center: '122.235151,40.667432',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0410',
        adcode: '211200',
        name: '铁岭市',
        center: '123.844279,42.290585',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0427',
        adcode: '211100',
        name: '盘锦市',
        center: '122.06957,41.124484',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0414',
        adcode: '210500',
        name: '本溪市',
        center: '123.770519,41.297909',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0419',
        adcode: '211000',
        name: '辽阳市',
        center: '123.18152,41.269402',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0412',
        adcode: '210300',
        name: '鞍山市',
        center: '122.995632,41.110626',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0421',
        adcode: '211300',
        name: '朝阳市',
        center: '120.451176,41.576758',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '370000',
    name: '山东省',
    center: '117.000923,36.675807',
    level: 'province',
    sub: [
      {
        citycode: '0535',
        adcode: '370600',
        name: '烟台市',
        center: '121.391382,37.539297',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0532',
        adcode: '370200',
        name: '青岛市',
        center: '120.355173,36.082982',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0631',
        adcode: '371000',
        name: '威海市',
        center: '122.116394,37.509691',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0635',
        adcode: '371500',
        name: '聊城市',
        center: '115.980367,36.456013',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0533',
        adcode: '370300',
        name: '淄博市',
        center: '118.047648,36.814939',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0539',
        adcode: '371300',
        name: '临沂市',
        center: '118.326443,35.065282',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0536',
        adcode: '370700',
        name: '潍坊市',
        center: '119.107078,36.70925',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0543',
        adcode: '371600',
        name: '滨州市',
        center: '118.016974,37.383542',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0633',
        adcode: '371100',
        name: '日照市',
        center: '119.461208,35.428588',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0546',
        adcode: '370500',
        name: '东营市',
        center: '118.66471,37.434564',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0538',
        adcode: '370900',
        name: '泰安市',
        center: '117.129063,36.194968',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0530',
        adcode: '371700',
        name: '菏泽市',
        center: '115.469381,35.246531',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0537',
        adcode: '370800',
        name: '济宁市',
        center: '116.587245,35.415393',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0531',
        adcode: '370100',
        name: '济南市',
        center: '117.000923,36.675807',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0632',
        adcode: '370400',
        name: '枣庄市',
        center: '117.557964,34.856424',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0534',
        adcode: '371400',
        name: '德州市',
        center: '116.307428,37.453968',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '320000',
    name: '江苏省',
    center: '118.767413,32.041544',
    level: 'province',
    sub: [
      {
        citycode: '0518',
        adcode: '320700',
        name: '连云港市',
        center: '119.178821,34.600018',
        level: 'city',
        sub: '',
      },
      {
        citycode: '025',
        adcode: '320100',
        name: '南京市',
        center: '118.767413,32.041544',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0527',
        adcode: '321300',
        name: '宿迁市',
        center: '118.275162,33.963008',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0513',
        adcode: '320600',
        name: '南通市',
        center: '120.864608,32.016212',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0517',
        adcode: '320800',
        name: '淮安市',
        center: '119.021265,33.597506',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0510',
        adcode: '320200',
        name: '无锡市',
        center: '120.301663,31.574729',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0519',
        adcode: '320400',
        name: '常州市',
        center: '119.946973,31.772752',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0512',
        adcode: '320500',
        name: '苏州市',
        center: '120.619585,31.299379',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0516',
        adcode: '320300',
        name: '徐州市',
        center: '117.184811,34.261792',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0514',
        adcode: '321000',
        name: '扬州市',
        center: '119.421003,32.393159',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0523',
        adcode: '321200',
        name: '泰州市',
        center: '119.915176,32.484882',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0515',
        adcode: '320900',
        name: '盐城市',
        center: '120.139998,33.377631',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0511',
        adcode: '321100',
        name: '镇江市',
        center: '119.452753,32.204402',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '610000',
    name: '陕西省',
    center: '108.948024,34.263161',
    level: 'province',
    sub: [
      {
        citycode: '0914',
        adcode: '611000',
        name: '商洛市',
        center: '109.939776,33.868319',
        level: 'city',
        sub: '',
      },
      {
        citycode: '029',
        adcode: '610100',
        name: '西安市',
        center: '108.948024,34.263161',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0915',
        adcode: '610900',
        name: '安康市',
        center: '109.029273,32.6903',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0916',
        adcode: '610700',
        name: '汉中市',
        center: '107.028621,33.077668',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0919',
        adcode: '610200',
        name: '铜川市',
        center: '108.979608,34.916582',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0912',
        adcode: '610800',
        name: '榆林市',
        center: '109.741193,38.290162',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0910',
        adcode: '610400',
        name: '咸阳市',
        center: '108.705117,34.333439',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0911',
        adcode: '610600',
        name: '延安市',
        center: '109.49081,36.596537',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0913',
        adcode: '610500',
        name: '渭南市',
        center: '109.502882,34.499381',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0917',
        adcode: '610300',
        name: '宝鸡市',
        center: '107.14487,34.369315',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: '021',
    adcode: '310000',
    name: '上海',
    center: '121.472644,31.231706',
    level: 'province',
    sub: [
      {
        citycode: '021',
        adcode: '310100',
        name: '上海市',
        center: '121.472644,31.231706',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '520000',
    name: '贵州省',
    center: '106.713478,26.578343',
    level: 'province',
    sub: [
      {
        citycode: '0858',
        adcode: '520200',
        name: '六盘水市',
        center: '104.846743,26.584643',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0856',
        adcode: '520600',
        name: '铜仁市',
        center: '109.191555,27.718346',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0852',
        adcode: '520300',
        name: '遵义市',
        center: '106.937265,27.706626',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0854',
        adcode: '522700',
        name: '黔南布依族苗族自治州',
        center: '107.517156,26.258219',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0857',
        adcode: '520500',
        name: '毕节市',
        center: '105.28501,27.301693',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0855',
        adcode: '522600',
        name: '黔东南苗族侗族自治州',
        center: '107.977488,26.583352',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0853',
        adcode: '520400',
        name: '安顺市',
        center: '105.932188,26.245544',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0859',
        adcode: '522300',
        name: '黔西南布依族苗族自治州',
        center: '104.897971,25.08812',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0851',
        adcode: '520100',
        name: '贵阳市',
        center: '106.713478,26.578343',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: '023',
    adcode: '500000',
    name: '重庆',
    center: '106.504962,29.533155',
    level: 'province',
    sub: [
      {
        citycode: '023',
        adcode: '500200',
        name: '重庆郊县',
        center: '108.170255,29.291965',
        level: 'city',
        sub: '',
      },
      {
        citycode: '023',
        adcode: '500100',
        name: '重庆市',
        center: '106.504962,29.533155',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '540000',
    name: '西藏自治区',
    center: '91.132212,29.660361',
    level: 'province',
    sub: [
      {
        citycode: '0897',
        adcode: '542500',
        name: '阿里地区',
        center: '80.105498,32.503187',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0894',
        adcode: '540400',
        name: '林芝市',
        center: '94.362348,29.654693',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0896',
        adcode: '540600',
        name: '那曲市',
        center: '92.060214,31.476004',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0893',
        adcode: '540500',
        name: '山南市',
        center: '91.766529,29.236023',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0891',
        adcode: '540100',
        name: '拉萨市',
        center: '91.132212,29.660361',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0895',
        adcode: '540300',
        name: '昌都市',
        center: '97.178452,31.136875',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0892',
        adcode: '540200',
        name: '日喀则市',
        center: '88.885148,29.267519',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '340000',
    name: '安徽省',
    center: '117.283042,31.86119',
    level: 'province',
    sub: [
      {
        citycode: '0561',
        adcode: '340600',
        name: '淮北市',
        center: '116.794664,33.971707',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1558',
        adcode: '341200',
        name: '阜阳市',
        center: '115.819729,32.896969',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0555',
        adcode: '340500',
        name: '马鞍山市',
        center: '118.507906,31.689362',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0562',
        adcode: '340700',
        name: '铜陵市',
        center: '117.816576,30.929935',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0556',
        adcode: '340800',
        name: '安庆市',
        center: '117.043551,30.50883',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0558',
        adcode: '341600',
        name: '亳州市',
        center: '115.782939,33.869338',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0563',
        adcode: '341800',
        name: '宣城市',
        center: '118.757995,30.945667',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0559',
        adcode: '341000',
        name: '黄山市',
        center: '118.317325,29.709239',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0550',
        adcode: '341100',
        name: '滁州市',
        center: '118.316264,32.303627',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0566',
        adcode: '341700',
        name: '池州市',
        center: '117.489157,30.656037',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0553',
        adcode: '340200',
        name: '芜湖市',
        center: '118.376451,31.326319',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0564',
        adcode: '341500',
        name: '六安市',
        center: '116.507676,31.752889',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0551',
        adcode: '340100',
        name: '合肥市',
        center: '117.283042,31.86119',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0554',
        adcode: '340400',
        name: '淮南市',
        center: '117.018329,32.647574',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0557',
        adcode: '341300',
        name: '宿州市',
        center: '116.984084,33.633891',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0552',
        adcode: '340300',
        name: '蚌埠市',
        center: '117.363228,32.939667',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '350000',
    name: '福建省',
    center: '119.306239,26.075302',
    level: 'province',
    sub: [
      {
        citycode: '0591',
        adcode: '350100',
        name: '福州市',
        center: '119.306239,26.075302',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0593',
        adcode: '350900',
        name: '宁德市',
        center: '119.527082,26.65924',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0597',
        adcode: '350800',
        name: '龙岩市',
        center: '117.02978,25.091603',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0599',
        adcode: '350700',
        name: '南平市',
        center: '118.178459,26.635627',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0592',
        adcode: '350200',
        name: '厦门市',
        center: '118.11022,24.490474',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0596',
        adcode: '350600',
        name: '漳州市',
        center: '117.661801,24.510897',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0594',
        adcode: '350300',
        name: '莆田市',
        center: '119.007558,25.431011',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0595',
        adcode: '350500',
        name: '泉州市',
        center: '118.589421,24.908853',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0598',
        adcode: '350400',
        name: '三明市',
        center: '117.635001,26.265444',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '430000',
    name: '湖南省',
    center: '112.982279,28.19409',
    level: 'province',
    sub: [
      {
        citycode: '0734',
        adcode: '430400',
        name: '衡阳市',
        center: '112.607693,26.900358',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0737',
        adcode: '430900',
        name: '益阳市',
        center: '112.355042,28.570066',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0730',
        adcode: '430600',
        name: '岳阳市',
        center: '113.132855,29.37029',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0738',
        adcode: '431300',
        name: '娄底市',
        center: '112.008497,27.728136',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0744',
        adcode: '430800',
        name: '张家界市',
        center: '110.479921,29.127401',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0736',
        adcode: '430700',
        name: '常德市',
        center: '111.691347,29.040225',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0743',
        adcode: '433100',
        name: '湘西土家族苗族自治州',
        center: '109.739735,28.314296',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0745',
        adcode: '431200',
        name: '怀化市',
        center: '109.97824,27.550082',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0731',
        adcode: '430100',
        name: '长沙市',
        center: '112.982279,28.19409',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0732',
        adcode: '430300',
        name: '湘潭市',
        center: '112.944052,27.82973',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0735',
        adcode: '431000',
        name: '郴州市',
        center: '113.032067,25.793589',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0746',
        adcode: '431100',
        name: '永州市',
        center: '111.608019,26.434516',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0733',
        adcode: '430200',
        name: '株洲市',
        center: '113.151737,27.835806',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0739',
        adcode: '430500',
        name: '邵阳市',
        center: '111.46923,27.237842',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '460000',
    name: '海南省',
    center: '110.33119,20.031971',
    level: 'province',
    sub: [
      {
        citycode: '0802',
        adcode: '469025',
        name: '白沙黎族自治县',
        center: '109.452606,19.224584',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1899',
        adcode: '469030',
        name: '琼中黎族苗族自治县',
        center: '109.839996,19.03557',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1894',
        adcode: '469002',
        name: '琼海市',
        center: '110.466785,19.246011',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0803',
        adcode: '469026',
        name: '昌江黎族自治县',
        center: '109.053351,19.260968',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1892',
        adcode: '469022',
        name: '屯昌县',
        center: '110.102773,19.362916',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0807',
        adcode: '469007',
        name: '东方市',
        center: '108.653789,19.10198',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0806',
        adcode: '469021',
        name: '定安县',
        center: '110.349235,19.684966',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1898',
        adcode: '469006',
        name: '万宁市',
        center: '110.388793,18.796216',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1896',
        adcode: '469024',
        name: '临高县',
        center: '109.687697,19.908293',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1897',
        adcode: '469001',
        name: '五指山市',
        center: '109.516662,18.776921',
        level: 'city',
        sub: '',
      },
      {
        citycode: '2802',
        adcode: '469027',
        name: '乐东黎族自治县',
        center: '109.175444,18.74758',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0801',
        adcode: '469029',
        name: '保亭黎族苗族自治县',
        center: '109.70245,18.636371',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1893',
        adcode: '469005',
        name: '文昌市',
        center: '110.753975,19.612986',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0809',
        adcode: '469028',
        name: '陵水黎族自治县',
        center: '110.037218,18.505006',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0805',
        adcode: '460400',
        name: '儋州市',
        center: '109.576782,19.517486',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0899',
        adcode: '460200',
        name: '三亚市',
        center: '109.508268,18.247872',
        level: 'city',
        sub: '',
      },
      {
        citycode: '2898',
        adcode: '460300',
        name: '三沙市',
        center: '112.34882,16.831039',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0804',
        adcode: '469023',
        name: '澄迈县',
        center: '110.007147,19.737095',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0898',
        adcode: '460100',
        name: '海口市',
        center: '110.33119,20.031971',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '630000',
    name: '青海省',
    center: '101.778916,36.623178',
    level: 'province',
    sub: [
      {
        citycode: '0974',
        adcode: '632500',
        name: '海南藏族自治州',
        center: '100.619542,36.280353',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0977',
        adcode: '632800',
        name: '海西蒙古族藏族自治州',
        center: '97.370785,37.374663',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0972',
        adcode: '630200',
        name: '海东市',
        center: '102.10327,36.502916',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0975',
        adcode: '632600',
        name: '果洛藏族自治州',
        center: '100.242143,34.4736',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0976',
        adcode: '632700',
        name: '玉树藏族自治州',
        center: '97.008522,33.004049',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0973',
        adcode: '632300',
        name: '黄南藏族自治州',
        center: '102.019988,35.517744',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0971',
        adcode: '630100',
        name: '西宁市',
        center: '101.778916,36.623178',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0970',
        adcode: '632200',
        name: '海北藏族自治州',
        center: '100.901059,36.959435',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '450000',
    name: '广西壮族自治区',
    center: '108.320004,22.82402',
    level: 'province',
    sub: [
      {
        citycode: '0779',
        adcode: '450500',
        name: '北海市',
        center: '109.119254,21.473343',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0777',
        adcode: '450700',
        name: '钦州市',
        center: '108.624175,21.967127',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0776',
        adcode: '451000',
        name: '百色市',
        center: '106.616285,23.897742',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0778',
        adcode: '451200',
        name: '河池市',
        center: '108.062105,24.695899',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0771',
        adcode: '450100',
        name: '南宁市',
        center: '108.320004,22.82402',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1772',
        adcode: '451300',
        name: '来宾市',
        center: '109.229772,23.733766',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0772',
        adcode: '450200',
        name: '柳州市',
        center: '109.411703,24.314617',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1771',
        adcode: '451400',
        name: '崇左市',
        center: '107.353926,22.404108',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0770',
        adcode: '450600',
        name: '防城港市',
        center: '108.345478,21.614631',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1774',
        adcode: '451100',
        name: '贺州市',
        center: '111.552056,24.414141',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0775',
        adcode: '450900',
        name: '玉林市',
        center: '110.154393,22.63136',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1755',
        adcode: '450800',
        name: '贵港市',
        center: '109.602146,23.0936',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0774',
        adcode: '450400',
        name: '梧州市',
        center: '111.297604,23.474803',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0773',
        adcode: '450300',
        name: '桂林市',
        center: '110.299121,25.274215',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '640000',
    name: '宁夏回族自治区',
    center: '106.278179,38.46637',
    level: 'province',
    sub: [
      {
        citycode: '1953',
        adcode: '640500',
        name: '中卫市',
        center: '105.189568,37.514951',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0952',
        adcode: '640200',
        name: '石嘴山市',
        center: '106.376173,39.01333',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0954',
        adcode: '640400',
        name: '固原市',
        center: '106.285241,36.004561',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0953',
        adcode: '640300',
        name: '吴忠市',
        center: '106.199409,37.986165',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0951',
        adcode: '640100',
        name: '银川市',
        center: '106.278179,38.46637',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '360000',
    name: '江西省',
    center: '115.892151,28.676493',
    level: 'province',
    sub: [
      {
        citycode: '0790',
        adcode: '360500',
        name: '新余市',
        center: '114.930835,27.810834',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0794',
        adcode: '361000',
        name: '抚州市',
        center: '116.358351,27.98385',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0792',
        adcode: '360400',
        name: '九江市',
        center: '115.992811,29.712034',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0701',
        adcode: '360600',
        name: '鹰潭市',
        center: '117.033838,28.238638',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0797',
        adcode: '360700',
        name: '赣州市',
        center: '114.940278,25.85097',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0791',
        adcode: '360100',
        name: '南昌市',
        center: '115.892151,28.676493',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0795',
        adcode: '360900',
        name: '宜春市',
        center: '114.391136,27.8043',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0796',
        adcode: '360800',
        name: '吉安市',
        center: '114.986373,27.111699',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0798',
        adcode: '360200',
        name: '景德镇市',
        center: '117.214664,29.29256',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0793',
        adcode: '361100',
        name: '上饶市',
        center: '117.971185,28.44442',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0799',
        adcode: '360300',
        name: '萍乡市',
        center: '113.852186,27.622946',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '330000',
    name: '浙江省',
    center: '120.153576,30.287459',
    level: 'province',
    sub: [
      {
        citycode: '0574',
        adcode: '330200',
        name: '宁波市',
        center: '121.549792,29.868388',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0577',
        adcode: '330300',
        name: '温州市',
        center: '120.672111,28.000575',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0573',
        adcode: '330400',
        name: '嘉兴市',
        center: '120.750865,30.762653',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0580',
        adcode: '330900',
        name: '舟山市',
        center: '122.106863,30.016028',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0576',
        adcode: '331000',
        name: '台州市',
        center: '121.428599,28.661378',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0578',
        adcode: '331100',
        name: '丽水市',
        center: '119.921786,28.451993',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0575',
        adcode: '330600',
        name: '绍兴市',
        center: '120.582112,29.997117',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0572',
        adcode: '330500',
        name: '湖州市',
        center: '120.102398,30.867198',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0570',
        adcode: '330800',
        name: '衢州市',
        center: '118.87263,28.941708',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0579',
        adcode: '330700',
        name: '金华市',
        center: '119.649506,29.089524',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0571',
        adcode: '330100',
        name: '杭州市',
        center: '120.153576,30.287459',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '130000',
    name: '河北省',
    center: '114.502461,38.045474',
    level: 'province',
    sub: [
      {
        citycode: '0315',
        adcode: '130200',
        name: '唐山市',
        center: '118.175393,39.635113',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0314',
        adcode: '130800',
        name: '承德市',
        center: '117.939152,40.976204',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0316',
        adcode: '131000',
        name: '廊坊市',
        center: '116.704441,39.523927',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0335',
        adcode: '130300',
        name: '秦皇岛市',
        center: '119.586579,39.942531',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0313',
        adcode: '130700',
        name: '张家口市',
        center: '114.884091,40.811901',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0317',
        adcode: '130900',
        name: '沧州市',
        center: '116.857461,38.310582',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0312',
        adcode: '130600',
        name: '保定市',
        center: '115.482331,38.867657',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0310',
        adcode: '130400',
        name: '邯郸市',
        center: '114.490686,36.612273',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0311',
        adcode: '130100',
        name: '石家庄市',
        center: '114.502461,38.045474',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0318',
        adcode: '131100',
        name: '衡水市',
        center: '115.665993,37.735097',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0319',
        adcode: '130500',
        name: '邢台市',
        center: '114.508851,37.0682',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '620000',
    name: '甘肃省',
    center: '103.823557,36.058039',
    level: 'province',
    sub: [
      {
        citycode: '0937',
        adcode: '620900',
        name: '酒泉市',
        center: '98.510795,39.744023',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1937',
        adcode: '620200',
        name: '嘉峪关市',
        center: '98.277304,39.786529',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0935',
        adcode: '620300',
        name: '金昌市',
        center: '102.187888,38.514238',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0931',
        adcode: '620100',
        name: '兰州市',
        center: '103.823557,36.058039',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1935',
        adcode: '620600',
        name: '武威市',
        center: '102.634697,37.929996',
        level: 'city',
        sub: '',
      },
      {
        citycode: '2935',
        adcode: '621200',
        name: '陇南市',
        center: '104.929379,33.388598',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0938',
        adcode: '620500',
        name: '天水市',
        center: '105.724998,34.578529',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0943',
        adcode: '620400',
        name: '白银市',
        center: '104.173606,36.54568',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0933',
        adcode: '620800',
        name: '平凉市',
        center: '106.684691,35.54279',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0930',
        adcode: '622900',
        name: '临夏回族自治州',
        center: '103.212006,35.599446',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0941',
        adcode: '623000',
        name: '甘南藏族自治州',
        center: '102.911008,34.986354',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0936',
        adcode: '620700',
        name: '张掖市',
        center: '100.455472,38.932897',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0934',
        adcode: '621000',
        name: '庆阳市',
        center: '107.638372,35.734218',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0932',
        adcode: '621100',
        name: '定西市',
        center: '104.626294,35.579578',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '510000',
    name: '四川省',
    center: '104.065735,30.659462',
    level: 'province',
    sub: [
      {
        citycode: '0839',
        adcode: '510800',
        name: '广元市',
        center: '105.829757,32.433668',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0827',
        adcode: '511900',
        name: '巴中市',
        center: '106.753669,31.858809',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0838',
        adcode: '510600',
        name: '德阳市',
        center: '104.398651,31.127991',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0817',
        adcode: '511300',
        name: '南充市',
        center: '106.082974,30.795281',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0816',
        adcode: '510700',
        name: '绵阳市',
        center: '104.741722,31.46402',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1833',
        adcode: '511400',
        name: '眉山市',
        center: '103.831788,30.048318',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0818',
        adcode: '511700',
        name: '达州市',
        center: '107.502262,31.209484',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0813',
        adcode: '510300',
        name: '自贡市',
        center: '104.773447,29.352765',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0826',
        adcode: '511600',
        name: '广安市',
        center: '106.633369,30.456398',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1832',
        adcode: '511000',
        name: '内江市',
        center: '105.066138,29.58708',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0825',
        adcode: '510900',
        name: '遂宁市',
        center: '105.571331,30.513311',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0832',
        adcode: '512000',
        name: '资阳市',
        center: '104.641917,30.122211',
        level: 'city',
        sub: '',
      },
      {
        citycode: '028',
        adcode: '510100',
        name: '成都市',
        center: '104.065735,30.659462',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0833',
        adcode: '511100',
        name: '乐山市',
        center: '103.761263,29.582024',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0835',
        adcode: '511800',
        name: '雅安市',
        center: '103.001033,29.987722',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0830',
        adcode: '510500',
        name: '泸州市',
        center: '105.443348,28.889138',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0834',
        adcode: '513400',
        name: '凉山彝族自治州',
        center: '102.258746,27.886762',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0831',
        adcode: '511500',
        name: '宜宾市',
        center: '104.630825,28.760189',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0812',
        adcode: '510400',
        name: '攀枝花市',
        center: '101.716007,26.580446',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0837',
        adcode: '513200',
        name: '阿坝藏族羌族自治州',
        center: '102.221374,31.899792',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0836',
        adcode: '513300',
        name: '甘孜藏族自治州',
        center: '101.963815,30.050663',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '220000',
    name: '吉林省',
    center: '125.3245,43.886841',
    level: 'province',
    sub: [
      {
        citycode: '0437',
        adcode: '220400',
        name: '辽源市',
        center: '125.145349,42.902692',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0438',
        adcode: '220700',
        name: '松原市',
        center: '124.823608,45.118243',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0431',
        adcode: '220100',
        name: '长春市',
        center: '125.3245,43.886841',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0432',
        adcode: '220200',
        name: '吉林市',
        center: '126.55302,43.843577',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0436',
        adcode: '220800',
        name: '白城市',
        center: '122.841114,45.619026',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0434',
        adcode: '220300',
        name: '四平市',
        center: '124.370785,43.170344',
        level: 'city',
        sub: '',
      },
      {
        citycode: '1433',
        adcode: '222400',
        name: '延边朝鲜族自治州',
        center: '129.513228,42.904823',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0439',
        adcode: '220600',
        name: '白山市',
        center: '126.427839,41.942505',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0435',
        adcode: '220500',
        name: '通化市',
        center: '125.936501,41.721177',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '530000',
    name: '云南省',
    center: '102.712251,25.040609',
    level: 'province',
    sub: [
      {
        citycode: '0873',
        adcode: '532500',
        name: '红河哈尼族彝族自治州',
        center: '103.384182,23.366775',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0874',
        adcode: '530300',
        name: '曲靖市',
        center: '103.797851,25.501557',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0886',
        adcode: '533300',
        name: '怒江傈僳族自治州',
        center: '98.854304,25.850949',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0870',
        adcode: '530600',
        name: '昭通市',
        center: '103.717216,27.336999',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0691',
        adcode: '532800',
        name: '西双版纳傣族自治州',
        center: '100.797941,22.001724',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0878',
        adcode: '532300',
        name: '楚雄彝族自治州',
        center: '101.546046,25.041988',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0879',
        adcode: '530800',
        name: '普洱市',
        center: '100.972344,22.777321',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0871',
        adcode: '530100',
        name: '昆明市',
        center: '102.712251,25.040609',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0888',
        adcode: '530700',
        name: '丽江市',
        center: '100.233026,26.872108',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0876',
        adcode: '532600',
        name: '文山壮族苗族自治州',
        center: '104.24401,23.36951',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0872',
        adcode: '532900',
        name: '大理白族自治州',
        center: '100.225668,25.589449',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0877',
        adcode: '530400',
        name: '玉溪市',
        center: '102.543907,24.350461',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0887',
        adcode: '533400',
        name: '迪庆藏族自治州',
        center: '99.706463,27.826853',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0875',
        adcode: '530500',
        name: '保山市',
        center: '99.167133,25.111802',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0883',
        adcode: '530900',
        name: '临沧市',
        center: '100.08697,23.886567',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0692',
        adcode: '533100',
        name: '德宏傣族景颇族自治州',
        center: '98.578363,24.436694',
        level: 'city',
        sub: '',
      },
    ],
  },
  {
    citycode: [],
    adcode: '140000',
    name: '山西省',
    center: '112.549248,37.857014',
    level: 'province',
    sub: [
      {
        citycode: '0353',
        adcode: '140300',
        name: '阳泉市',
        center: '113.583285,37.861188',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0351',
        adcode: '140100',
        name: '太原市',
        center: '112.549248,37.857014',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0357',
        adcode: '141000',
        name: '临汾市',
        center: '111.517973,36.08415',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0359',
        adcode: '140800',
        name: '运城市',
        center: '111.003957,35.022778',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0356',
        adcode: '140500',
        name: '晋城市',
        center: '112.851274,35.497553',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0355',
        adcode: '140400',
        name: '长治市',
        center: '113.113556,36.191112',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0350',
        adcode: '140900',
        name: '忻州市',
        center: '112.733538,38.41769',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0349',
        adcode: '140600',
        name: '朔州市',
        center: '112.433387,39.331261',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0354',
        adcode: '140700',
        name: '晋中市',
        center: '112.736465,37.696495',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0358',
        adcode: '141100',
        name: '吕梁市',
        center: '111.134335,37.524366',
        level: 'city',
        sub: '',
      },
      {
        citycode: '0352',
        adcode: '140200',
        name: '大同市',
        center: '113.295259,40.09031',
        level: 'city',
        sub: '',
      },
    ],
  },
]
